import React from 'react';
import './App.css';

import FullWidthTabs from './components/tabs'
class App extends React.Component {
    render() {
        return (
            <div>
                <FullWidthTabs/>
            </div>
        )
    }
}

export default App;
