import React from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from '../marker'

const markers = [
    {
        lat: 10.767566,
        lng: 106.67434,
        text: "Dist 3",
        value: "No_1",
    },
    {
        lat: 10.7711155,
        lng: 106.6743807,
        text: "Dist 10",
        value: "No_2",
    },
    {
        lat: 10.8047125,
        lng: 106.7017627,
        text: "Bình Thạnh District - 1",
        value: "No_3",
    },
    {
        lat: 10.7978782,
        lng: 106.7037372,
        text: "Bình Thạnh District - 2",
        value: "No_4",
    },
    {
        lat: 10.7387802,
        lng: 106.6565348,
        text: "Dist 8",
        value: "No_5",
    },
    {
        lat: 11.0543891,
        lng: 106.6652194,
        text: "VGU B.104",
        value: "VGU_1",
    },
    {
        lat: 11.0545158,
        lng: 106.6655872,
        text: "VGU Campus",
        value: "VGU_2",
    },
    {
        lat: 11.0511025,
        lng: 106.6610779,
        text: "Định Hòa Dorm",
        value: "VGU_3",
    },
    // {
    //     lat: 11.0543743,
    //     lng: 106.6651359,
    //     text: "Node AS",
    //     value: "NodeAS",
    // },

]

class GoogleMap extends React.Component {
    static defaultProps = {
        center: {
            lat: 10.9212591,
            lng: 106.6747851
        },
        zoom: 11
    };

    render() {
        const MapOption = {
            scrollwheel: false,
            fullscreenEnabled: false,
            zoomControl: true,
            rotateControl: false,
            clickableIcon: false};

        const {onMarkerClick, sensorData} = this.props

        return (
            // Important! Always set the container height explicitly
            <div style={{ height: '85vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyAhIR1Y7EVKUIXLhzwcx12GxxelFt-Nu2c" }}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                    options={MapOption}
                >
                    {markers.map(marker => (
                        <Marker
                            lat={marker.lat}
                            lng={marker.lng}
                            text={marker.text}
                            value={marker.value}
                            onClick={onMarkerClick}
                            sensorData={sensorData}
                        />
                    ))}
                </GoogleMapReact>
            </div>
        );
    }
}

export default GoogleMap;