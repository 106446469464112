import firebase from 'firebase'

const config = {
    apiKey: "AIzaSyC9RzHCMkEQonozFLH2fCGmq0WJzBi_bDo",
    authDomain: "dust-map-vn.firebaseapp.com",
    databaseURL: "https://dust-map-vn.firebaseio.com",
    projectId: "dust-map-vn",
    storageBucket: "dust-map-vn.appspot.com",
    messagingSenderId: "189820967649",
};

firebase.initializeApp(config);
export default firebase;